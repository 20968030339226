import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, resolveComponent as _resolveComponent, createBlock as _createBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, createVNode as _createVNode, createElementVNode as _createElementVNode, normalizeStyle as _normalizeStyle } from "vue"

const _hoisted_1 = {
  key: 1,
  class: "font-primary text-xl pl-2"
}
const _hoisted_2 = {
  key: 2,
  class: "mt-6 font-secondary text-lg"
}
const _hoisted_3 = { class: "flex px-3 my-6" }
const _hoisted_4 = {
  key: 0,
  class: "w-20 mr-3"
}
const _hoisted_5 = {
  key: 1,
  class: "w-20"
}
const _hoisted_6 = {
  key: 2,
  class: "w-20 ml-auto"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_TextInputForEditorVue = _resolveComponent("TextInputForEditorVue")!
  const _component_scmcVue = _resolveComponent("scmcVue")!
  const _component_HollowButtonVue = _resolveComponent("HollowButtonVue")!
  const _component_SolidButtonVue = _resolveComponent("SolidButtonVue")!

  return (_openBlock(), _createElementBlock("div", {
    class: "lg:px-96 sm:px-32 px-5 py-10 min-h-screen",
    style: _normalizeStyle('background-color:light' + _ctx.branch.scene?.bg)
  }, [
    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.els, (el) => {
      return (_openBlock(), _createElementBlock("div", {
        key: el.id
      }, [
        (_ctx.inEditMode)
          ? (_openBlock(), _createBlock(_component_TextInputForEditorVue, {
              key: 0,
              modelValue: el.title,
              "onUpdate:modelValue": ($event: any) => ((el.title) = $event)
            }, null, 8, ["modelValue", "onUpdate:modelValue"]))
          : (_openBlock(), _createElementBlock("div", _hoisted_1, _toDisplayString(el.title), 1)),
        (el.__typename !== 'empty')
          ? (_openBlock(), _createElementBlock("div", _hoisted_2, [
              (el.__typename == 'McEl')
                ? (_openBlock(), _createBlock(_component_scmcVue, {
                    key: 0,
                    "in-edit-mode": _ctx.inEditMode,
                    node: el,
                    "onUpdate:value": ($event: any) => (_ctx.updateAnswer($event, el.id))
                  }, null, 8, ["in-edit-mode", "node", "onUpdate:value"]))
                : _createCommentVNode("", true)
            ]))
          : _createCommentVNode("", true)
      ]))
    }), 128)),
    _createElementVNode("div", _hoisted_3, [
      (_ctx.branch.isBackAvail)
        ? (_openBlock(), _createElementBlock("div", _hoisted_4, [
            _createVNode(_component_HollowButtonVue, {
              text: 'back',
              onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.goBack()))
            })
          ]))
        : _createCommentVNode("", true),
      (_ctx.branch.next)
        ? (_openBlock(), _createElementBlock("div", _hoisted_5, [
            (_ctx.submitState.submitLoading.value)
              ? (_openBlock(), _createBlock(_component_SolidButtonVue, {
                  key: 0,
                  icon: 'snowflake',
                  "icon-type": 'far',
                  "icon-meta": 'fa-spin',
                  "is-selected": true
                }))
              : (_openBlock(), _createBlock(_component_SolidButtonVue, {
                  key: 1,
                  text: 'next',
                  onClick: _cache[1] || (_cache[1] = ($event: any) => (_ctx.goNext()))
                }))
          ]))
        : _createCommentVNode("", true),
      (_ctx.branch.isSubmitAvail)
        ? (_openBlock(), _createElementBlock("div", _hoisted_6, [
            (_ctx.submitState.submitLoading.value)
              ? (_openBlock(), _createBlock(_component_SolidButtonVue, {
                  key: 0,
                  icon: 'snowflake',
                  "icon-type": 'far',
                  "icon-meta": 'fa-spin',
                  "is-selected": true
                }))
              : (_openBlock(), _createBlock(_component_SolidButtonVue, {
                  key: 1,
                  text: 'submit',
                  onClick: _cache[2] || (_cache[2] = ($event: any) => (_ctx.submitFinish()))
                }))
          ]))
        : _createCommentVNode("", true)
    ])
  ], 4))
}