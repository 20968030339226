import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, resolveComponent as _resolveComponent, createVNode as _createVNode, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, normalizeClass as _normalizeClass } from "vue"

const _hoisted_1 = { class: "flex flex-col" }
const _hoisted_2 = ["onClick"]
const _hoisted_3 = {
  key: 0,
  class: "flex"
}
const _hoisted_4 = ["onClick"]
const _hoisted_5 = ["onClick"]
const _hoisted_6 = {
  key: 1,
  class: "w-full"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_font_awesome_icon = _resolveComponent("font-awesome-icon")!
  const _component_TextInputForEditor = _resolveComponent("TextInputForEditor")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.options, (option, optIndex) => {
      return (_openBlock(), _createElementBlock("div", {
        key: optIndex,
        class: _normalizeClass(["flex flex-row hover:bg-blue-100 rounded-lg p-2", !_ctx.inEditMode ? 'cursor-pointer' : '']),
        onClick: ($event: any) => (_ctx.inEditMode ? (_ctx.inputVal = optIndex) : '')
      }, [
        _createVNode(_component_font_awesome_icon, {
          icon: optIndex === _ctx.inputVal ? ['fas', 'circle'] : ['far', 'circle'],
          class: "mr-2 mt-2"
        }, null, 8, ["icon"]),
        (_ctx.inEditMode)
          ? (_openBlock(), _createElementBlock("div", _hoisted_3, [
              _createVNode(_component_TextInputForEditor, {
                modelValue: _ctx.options[optIndex],
                "onUpdate:modelValue": ($event: any) => ((_ctx.options[optIndex]) = $event)
              }, null, 8, ["modelValue", "onUpdate:modelValue"]),
              (_ctx.options.length > 1)
                ? (_openBlock(), _createElementBlock("p", {
                    key: 0,
                    class: "font-bold text-lg font-mono cursor-pointer",
                    onClick: ($event: any) => (_ctx.delOpt(optIndex))
                  }, " x ", 8, _hoisted_4))
                : _createCommentVNode("", true),
              _createElementVNode("p", {
                class: "font-bold text-lg font-mono cursor-pointer",
                onClick: ($event: any) => (_ctx.addOpt(optIndex))
              }, " + ", 8, _hoisted_5)
            ]))
          : (_openBlock(), _createElementBlock("div", _hoisted_6, _toDisplayString(option), 1))
      ], 10, _hoisted_2))
    }), 128)),
    (_ctx.inputVal !== null)
      ? (_openBlock(), _createElementBlock("div", {
          key: 0,
          class: "font-normal text-lg text-teal-800 mt-5 cursor-pointer",
          onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.inputVal = null))
        }, " Clear Selection "))
      : _createCommentVNode("", true)
  ]))
}