import { vModelText as _vModelText, withDirectives as _withDirectives, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = ["placeholder"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return _withDirectives((_openBlock(), _createElementBlock("textarea", {
    ref: "textele",
    "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.inputValue) = $event)),
    type: "text",
    class: "bg-transparent border-gray-500 focus:outline-none border-l-4 pl-4 w-full overflow-hidden resize-none",
    placeholder: _ctx.placeholder,
    rows: "1",
    maxlength: "1000"
  }, null, 8, _hoisted_1)), [
    [_vModelText, _ctx.inputValue]
  ])
}