
import { defineComponent } from "vue";

import SolidButtonVue from "../particles/SolidButton.vue";
import HollowButtonVue from "../particles/HollowButton.vue";
import scmcVue from "../atoms/question_types/scmc.vue";
import TextInputForEditorVue from "@/components/particles/TextInputForEditor.vue";

export default defineComponent({
  props: {
    inEditMode: {
      type: Boolean,
      required: false,
      default: false,
    },
    els: {
      required: true,
      type: Object,
    },
    submitState: {
      type: Object,
      required: false,
      default: () => {
        return { submitLoading: false, submitError: null };
      },
    },
    branch: {
      type: Object,
      required: true,
    },
    updateAnswer: {
      required: false,
      type: Function,
      default: () => {
        return function () {
          return;
        };
      },
    },
    goBack: {
      required: false,
      type: Function,
      default: () => {
        return function () {
          return;
        };
      },
    },
    goNext: {
      required: false,
      type: Function,
      default: () => {
        return function () {
          return;
        };
      },
    },
    submitFinish: {
      required: false,
      type: Function,
      default: () => {
        return function () {
          return;
        };
      },
    },
  },
  components: {
    SolidButtonVue,
    HollowButtonVue,
    scmcVue,
    TextInputForEditorVue,
  },
});
