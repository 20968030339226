
import { computed, defineComponent, reactive, ref } from "vue";

import UserNavVue from "@/components/molecules/UserNav.vue";
import TextInputWithErrorVue from "@/components/particles/TextInputWithError.vue";
import SolidButtonVue from "@/components/particles/SolidButton.vue";
import FormPageVue from "@/components/polymers/FormPage.vue";

export default defineComponent({
  components: {
    UserNavVue,
    TextInputWithErrorVue,
    SolidButtonVue,
    FormPageVue,
  },
  setup() {
    const show = reactive({
      editPane: "scene", // can be 'setting', 'style', 'graph', 'scene', ''
    });
    const isPublishing = ref(false);

    const localForm = reactive({
      branches: [
        {
          id: "nonde",
          __typename: "BranchNode",
          code: "hello-world",
          scene: "nonve",
          next: "null",
          isBackAvail: false,
          isSubmitAvail: true,
        },
      ],
      scenes: [
        {
          id: "nonve",
          __typename: "Scene",
          bg: "blue",
          elements: [
            {
              row: 0,
              col: 0,
              id: "nonce",
            },
          ],
        },
      ],
      elements: [
        {
          id: "nonce",
          __typename: "McEl",
          title: "What is the capital city of India?",
          options: ["New Delhi", "Bengaluru", "Chennai", "Hyderabad"],
        },
      ],
    });

    const currentBranchID = ref("nonde");
    const getBranch = computed(() => {
      const currBranch = localForm.branches.find(
        (o) => o.id === currentBranchID.value
      );
      const sceneID = currBranch?.scene;
      const scene = localForm.scenes.find((o) => o.id === sceneID);
      if (currBranch) return { ...currBranch, scene: scene };
      return { code: "" };
    });
    const getScene = computed(() => {
      const currBranch = localForm.branches.find(
        (o) => o.id === currentBranchID.value
      );
      const sceneID = currBranch?.scene;
      return localForm.scenes.find((o) => o.id === sceneID);
    });
    const getEls = computed(() => {
      // optimise this TODO
      const sceneID = localForm.branches.find(
        (o) => o.id === currentBranchID.value
      )?.scene;
      const elIDs =
        localForm.scenes.find((o) => o.id === sceneID)?.elements || [];
      return elIDs.reduce((res: any, x) => {
        const k = localForm.elements.find((o) => o.id === x.id);
        if (k) res[x.row] = k;
        return res;
      }, []);
    });

    const addEl = (type = "McEl") => {
      var S4 = function () {
        return (((1 + Math.random()) * 0x10000) | 0).toString(16).substring(1);
      };
      const newID =
        S4() +
        S4() +
        "-" +
        S4() +
        "-" +
        S4() +
        "-" +
        S4() +
        "-" +
        S4() +
        S4() +
        S4();
      localForm.elements.push({
        id: newID,
        __typename: type,
        title: "",
        options: [""],
      });
      getScene.value?.elements.push({
        row: getScene.value.elements.length,
        col: 0,
        id: newID,
      });
    };
    const delEl = () => {
      const scene = getScene.value;
      if (!scene) return;
      const elInd = scene.elements.findIndex(
        (o) => o.row === scene.elements.length - 1
      );
      if (!elInd) return;
      const elIndex = scene.elements.splice(elInd, 1);
      if (!elIndex?.[0]?.id) return;
      const t_ind = localForm.elements.findIndex((o) => o.id === elIndex[0].id);
      localForm.elements.splice(t_ind, 1);
    };
    const moveEl = (newpos: number, oldpos: number) => {
      const scene = getScene.value;
      if (!scene) return;
      const elInd = scene.elements.findIndex((o) => o.row === oldpos);
      const elInd1 = scene.elements.findIndex((o) => o.row === newpos);
      if (elInd < 0 || elInd1 < 0) return;
      scene.elements[elInd1].row = oldpos;
      scene.elements[elInd].row = newpos;
    };

    const toggleEditPaneMode = (mode: string) => {
      if (show.editPane === mode) show.editPane = "";
      else show.editPane = mode;
    };

    const PublishForm = function () {
      if (isPublishing.value) return;
      isPublishing.value = true;
      // $store.dispatch("PublishForm").then((response) => {
      //   if (!response)
      //     modalMessage.value =
      //       "Unexpected Error in publishing the form.Check your network connection and try again.";
      //   else modalMessage.value = "Form Published!";
      //   isPublishing.value = false;
      // });
    };

    return {
      show,
      isPublishing,
      toggleEditPaneMode,
      PublishForm,

      getBranch,
      getScene,
      getEls,

      addEl,
      delEl,
      moveEl,
    };
  },
});
