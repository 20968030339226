
import { defineComponent, toRef } from "vue";
import TextInputForEditor from "@/components/particles/TextInputForEditor.vue";

export default defineComponent({
  props: {
    node: {
      type: Object,
      required: true,
    },
    inEditMode: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  emits: ["update:value"],
  computed: {
    inputVal: {
      get() {
        return this.node.answer ? Number(this.node.answer) : null;
      },
      set(value: number) {
        this.$emit(
          "update:value",
          value !== null ? String(value) || "0" : null
        );
      },
    },
  },
  setup(props) {
    const node = toRef(props, "node");

    const delOpt = (pos: number) => {
      node.value.options.splice(pos, 1);
    };
    const addOpt = (pos: number) => {
      node.value.options.splice(pos + 1, 0, "");
    };

    return {
      options: node.value.options,
      delOpt,
      addOpt,
    };
  },
  components: { TextInputForEditor },
});
